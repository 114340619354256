@keyframes shimmer {
  0% {
    background-position: -200% center;
  }
  100% {
    background-position: 200% center;
  }
}

.AiProgressMessage {
  &--loading {
    background-image: linear-gradient(to right, #7E7B96, #D5D2E9, #7E7B96);
    background-size: 200% auto;
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    animation: shimmer 2s linear infinite;
  }
}
