.TicketDetails {
  .DetailGroup {
    &:has(.AssigneeSelect) {
      // Special case because the assignee select sometimes has the 'assign to me' link
      align-items: flex-start !important;

      label {
        margin-top: 10px !important;
      }
    }

    .ClearyReactSelect__control {
      height: 32px;
      border: none;
      box-shadow: none;

      &:hover,
      &.ClearyReactSelect__control--is-focused,
      &.ClearyReactSelect__control--menu-is-open {
        background-color: var(--lightest-gray);
      }
    }

    .DateTimePicker,
    .ClearyReactSelect__placeholder {
      font-size: 13px;
      font-weight: 400;
    }

    &:hover {
      .ClearyReactSelect__clear-indicator {
        display: flex;

        svg {
          height: 16px;
          width: 16px;

          path {
            fill: var(--mid-gray);
          }

          &:hover path {
            @extend .pointer;
            fill: var(--dark-gray);
          }
        }
      }
    }

    .ClearyReactSelect__clear-indicator {
      display: none;
    }

    .ClearyReactSelect__indicator-separator,
    .ClearyReactSelect__dropdown-indicator {
      display: none;
    }

    .ClearyReactSelect--is-disabled {
      background-color: transparent;
    }

    .EmployeeSearch {
      .ClearyReactSelect__single-value {
        > span > span {
          font-size: 13px;
        }

        > span > img {
          width: 20px !important;
          height: 20px !important;
        }
      }
    }

    div:has(> .react-datepicker-wrapper) {
      width: 100%;
      flex: 1;
    }

    .react-datepicker__input-container {
      &:has(.DateTimePicker:disabled) {
        pointer-events: none;
      }

      .DateTimePicker {
        height: 32px;
        border: none;
        padding: 0 10px;

        &:disabled {
          background-color: transparent;
          color: var(--text-color-primary);
          opacity: 1;
        }
      }

      .react-datepicker__close-icon {
        top: 3px !important;
        display: none;

        &::after {
          color: var(--mid-gray);
        }

        &:hover::after {
          color: var(--dark-gray);
        }
      }

      &:hover {
        .DateTimePicker {
          background-color: var(--lightest-gray);
        }

        .react-datepicker__close-icon {
          display: flex;
        }
      }
    }

    .TicketNumber, .AssignToMeLink {
      padding: 0 10px;
    }
  }
}
