.SuggestedMessageEntry {
  &-wrapper {
    padding: 24px;
  }

  position: relative;
  margin-top: -4px;
  // z-index: -1;
  background-color: var(--white-pure);
  text-align: left;

  sup, sub {
    display: none;
  }
}
