.TicketEscalationPopover {
  width: 363px;
  box-shadow: var(--card-box-shadow);

  .LabeledFormFieldContainer {
    width: 100%;
  }

  .Description {
    font-size: 14px;
  }
}

.TicketEscalationPopover:has(.TicketEscalationSuccess) {
  border: 1px solid var(--success-color);
}
