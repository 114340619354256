.TicketTable {
  .TicketTitle {
    font-size: 15px !important;
    font-weight: 500;
  }

  .OriginIcon {
    width: 34px;
    display: flex;
    align-items: center;
    justify-content: center;

    svg, img {
      width: 16px;
    }
  }

  .RequesterInfo {
    font-size: 12px !important;
    margin-left: 34px;

    .UserLink {
      font-size: 12px !important;
    }

    .OverdueLabel {
      color: var(--status-label-red-text-color);
      font-weight: 700;
    }
  }

  tbody {
    td, div {
      font-size: 13px !important;
    }
  }
}
