.pointer {
  cursor: pointer;
}

.cursor-default {
  cursor: default;
}

.cursor-text {
  cursor: text;
}

@mixin grab-cursor {
  cursor: url('https://www.google.com/intl/en_ALL/mapfiles/openhand.cur'), all-scroll;
  cursor: -webkit-grab;
  cursor: -moz-grab;
  cursor: -o-grab;
  cursor: -ms-grab;
  cursor: grab;
}

@mixin grabbing-cursor {
  cursor: url('https://www.google.com/intl/en_ALL/mapfiles/closedhand.cur'), all-scroll;
  cursor: -webkit-grabbing;
  cursor: -moz-grabbing;
  cursor: -o-grabbing;
  cursor: -ms-grabbing;
  cursor: grabbing;
}

.drag-handle {
  @include grab-cursor;

  &:active {
    @include grabbing-cursor;
  }
}

.disabled-link {
  pointer-events: none;
  cursor: not-allowed;
  opacity: 0.3;
}

.pointer-events-none {
  pointer-events: none;
}

.link {
  @include linkText(--highlight-color, --link-font-weight);
}

.cursor-not-allowed {
  cursor: not-allowed;
}

.btn.btn-icon:focus {
  outline: none;
  box-shadow: none;
}

.zindex-base {
  z-index: $zindex-base;
}

.zindex-1 {
  z-index: $zindex-one;
}

.zindex-fixed {
  z-index: $zindex-fixed;
}

.zindex-dropdown {
  z-index: $zindex-dropdown;
}

.zindex-popover {
  z-index: $zindex-popover;
}

.center-v-and-h {
  position: absolute;
  z-index: $zindex-popover;
  // attempt placing close to the middle
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

// This might IS provisional
// We want to align event list page in the middle
// while the right top questions widget is built
// Since the middle column is col-9, the right and left sides
// Must have 1.5 columns an each side.
@media (min-width: 768px) {
  .offset-md-1-5 {
    // offset-md-1 -> 8.333333%
    // 12.4999995% = 8.333333% * 1.5
    margin-left: 12.4999995%;
  }
}

.visibility-hidden {
  visibility: hidden;
}

.overflow-y-auto {
  overflow-y: auto;
}

.overflow-y-scroll {
  overflow-y: scroll;
}

.visible-scroll {
  ::-webkit-scrollbar {
    background-color: transparent;
    width: 6px;
    height: 6px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: var(--light-gray);
    border-radius: 6px;
  }
}

.overflow-y-hidden {
  overflow-y: hidden;
}

.overflow-x-auto {
  overflow-x: auto;
}

.border-radius-0 {
  border-radius: 0 !important;
}

.max-width-max-content {
  max-width: max-content;
}

.opacity-on-hover:hover {
  opacity: 0.7 !important;
}

.mirror {
  -moz-transform: scale(-1, 1);
  -webkit-transform: scale(-1, 1);
  -o-transform: scale(-1, 1);
  -ms-transform: scale(-1, 1);
  transform: scale(-1, 1);
}

.pt-100 {
  padding-top: 100%;
}

.top-2 {
  top: 8px;
}

.right-2 {
  right: 8px;
}
