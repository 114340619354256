.AdminPageContent {
  margin-left: 0;
  transition: margin-left 0.5s ease-in-out;
  // Bottom space to prevent 'send feedback' button to block things on the screen
  padding-bottom: 4em;

  .container {
    max-width: 1320px;
  }

  &.isDesktopNavOpen {
    margin-left: 0;
  }

  @include small-size {
    width: 100vw;
    padding-bottom: 0;
  }
}

// new style of Admin Header
header.AdminHeader {
  padding: 18px 40px;
  background-color: var(--white-pure);
  box-shadow: var(--admin-header-box-shadow);
  margin-bottom: 20px;
}

// content style when using new admin header
.AdminContent {
  margin: var(--admin-content-y-margin) var(--admin-content-x-margin);
}
