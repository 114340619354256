.AiPrompts {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 12px;

  .Prompt {
    @extend .hover-highlight-border, .pointer;

    padding: 9px 17px;
    border: 1px solid var(--border-color);
    border-radius: var(--square-border-radius-large);
    font-size: 13px;
  }
}
