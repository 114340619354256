.AskAiNavbarButton {
  font-size: 1rem;
  padding: 0 14px;
  min-width: auto;
  border-radius: var(--square-border-radius) !important;
  padding: 0 !important;
}

.AskAiModal {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 100%;
  max-width: 556px;
  height: calc(100vh - var(--nav-height) - 40px);
  max-height: 750px;
  z-index: 1000;
  box-shadow: var(--card-box-shadow);
  border-radius: 15px;
  display: flex;
  flex-direction: column;

  .AskAiModal-header {
    background: linear-gradient(258.12deg, var(--ask-ai-end-color) 10.82%, var(--ask-ai-start-color) 104.37%);
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    border: 1px solid var(--ask-ai-border-color);
    border-bottom: none;
    box-shadow: var(--card-box-shadow);
    position: relative;

    h4 {
      font-size: 15px;

      .title-text {
        position: relative;
        top: 1px;
      }
    }

    button {
      background: transparent;
      border: none;
    }

    .clock-icon {
      svg {
        width: 24px;
      }
    }

    .CloseIcon {
      height: auto;
      width: 18px;
      position: initial;

      &, &:hover {
        stroke: $white-pure;
        fill: $white-pure;
      }
    }
  }

  .AskAiModal-subheader {
    background: linear-gradient(258.12deg, var(--ask-ai-end-color) 10.82%, var(--ask-ai-start-color) 104.37%);
    border: 1px solid var(--ask-ai-border-color);
    border-bottom: none;
    border-top: none;

    .greeting-small {
      font-size: 18px;
    }

    .greeting-normal {
      font-size: 24px;
    }
  }

  .AskAiModal-body {
    overflow-y: auto;
    background: white;
    min-height: 200px;
    border: 1px solid var(--ask-ai-border-color);
    border-bottom: none;
  }

  .AskAiForm {
    display: block;
    background: white;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    border: 1px solid var(--ask-ai-border-color);
    border-top: none;
  }

  .AiOverview__container {
    max-width: 80%;
  }

  .AiOverview {
    .AIMessageIcon {
      svg {
        width: 14px;
        height: 14px;
      }
    }

    .AIMessageText {
      font-size: 13px;
      font-weight: 600;
    }

    .card-body {
      .TiptapView {
        &, * {
          font-size: 13px;
        }
      }

      p {
        font-size: 13px;
      }
    }
  }

  .collapsable-greeting {
    transition: height 0.2s ease-in-out;
    overflow: hidden;
  }
}

.UserMessage {
  max-width: 80%;
  margin-left: auto;

  .UserMessage-content {
    word-break: break-word;
    background-color: var(--highlight-color);
    font-size: 13px;
  }
}

.AiGreetingMessage-button {
  color: var(--text-color-primary);
  border-radius: 8px !important;
  font-weight: 400 !important;
  padding: 7px 14px;
  height: auto;
  width: auto;
}

.AiMessage {
  .CopyButtonSmallNarrow {
    position: absolute;
    top: 8px;
    right: 0;
    min-width: initial !important;

    svg {
      path {
        fill: var(--text-color-secondary);
      }
    }

    &:hover {
      svg {
        path {
          fill: var(--highlight-color);
        }
      }
    }
  }

  .OfferTicketEscalationFooter {
    button {
      min-width: 70px !important;

      &:hover {
        background-color: var(--dynamic-color-link-color-lighter--link-color) !important;
      }
    }
  }

  .AiAssistantAnswerFeedback {
    flex-direction: column;
    align-items: start !important;
  }
}
