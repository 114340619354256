.AiAssistantAnswerFeedback {
  .FeedbackButton {
    cursor: pointer;

    min-width: 73px !important;

    &.selected, &:hover {
      background-color: var(--dynamic-color-link-color-lighter--link-color) !important;
    }

    &:disabled {
      pointer-events: none;
    }
  }
}
