.FAQForm {
  .TiptapToolbarWrapper {
    margin-bottom: 0 !important;
  }

  .TiptapEditorContentWrapper {
    @extend .p-3;

    background-color: var(--white-pure);
  }
}
