.TicketEntries {
  .Entry + .Message, .Entry + .CommentEntry {
    margin-top: -20px;
  }

  > .d-flex > div:last-child .Entry__separator:last-child {
    display: none !important;
  }

  sub, sup {
    display: none;
  }

  .DropdownButton {
    button {
      @extend .text-small;
    }
  }

  .MessageEntry,
  .CommentEntry {
    @extend .bordered;

    border-radius: var(--square-border-radius-xlarge);
    padding: 24px;
  }

  .MessageEntry {
    background-color: var(--ticket-message-background-color);
  }

  .UnassignedUserIcon {
    background-color: var(--light-gray);
  }

  // This is needed so the scrollbar is outside the scrollable container
  margin-right: -24px;
  .scrollable-container {
    padding-right: 24px;
  }
}
