.CreateEntryInput {
  .TiptapEditor {
    transition: all 0.5s ease-out;
    height: 56px;
    min-height: 56px !important;
    border-radius: var(--square-border-radius-xlarge);

    &.expanded {
      height: auto;
      height: 250px !important;
    }

    .TiptapToolbar {
      border-radius: var(--square-border-radius-xlarge);
    }

    &:has(.ProseMirror:focus) {
      border-color: var(--highlight-color);
    }

    .TiptapEditorContentWrapper {
      overflow: auto;
    }

    &.isPublicComment {
      background-color: var(--ticket-comment-background-color);
    }

    &.isPrivateComment {
      background-color: var(--ticket-private-comment-background-color);
    }
  }

  .ReplyButton, .CommentButton {
    height: 30px;
    min-width: 100px;
    font-size: 13px !important;

    svg {
      height: 12px;
      width: 12px;

      path {
        fill: currentColor;
      }
    }
  }
}

