.FaqSuggestions {
  .white-bg-table {
    border-radius: 0;

    thead th, tbody td {
      padding: 1rem 1rem;
      border: none;
    }

    tr {
      border-bottom: 1px solid var(--base-layout-border-color);
    }
  }

  .PaginationContainer {
    padding: 0 1rem;
  }
}
