.FaqSuggestionsEditorSidebar {
  .RootSidebarContent {
    display: flex;
    flex-direction: column;
    gap: 30px;
  }

  .btn-with-icon {
    @extend .text-small-important;

    padding: 5px 9px !important;
    min-width: 70px !important;
    height: 30px !important;
    gap: 4px;

    svg {
      width: 14px;
      height: 14px;
    }
  }

  .btn-secondary {
    color: var(--text-color-primary);
    border-color: var(--border-color);
  }

  .SidebarCloseButton {
    top: -15px;
  }

  .TiptapEditorContentWrapper {
    @extend .p-3;
  }
}
