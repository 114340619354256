.ViewTicketSidebar {
  .Sidebar {
    padding: 0 !important;
    width: 1122px !important;
    max-width: 90vw;
  }

  .Sidebar__header {
    margin-bottom: 0 !important;
    height: 0 !important;

    .close-button {
      z-index: $zindex-dropdown;
    }
  }

  .RootSidebarContent {
    margin-right: 0 !important;
    padding-right: 0 !important;
  }

  .ViewTicketSidebarLeftColumn {
    padding: 30px 24px;

    hr {
      width: calc(100% + 48px); // 24px padding on each side
      border-bottom: 1px solid var(--border-color);
      margin: 0 0 0 -24px;
    }
  }

  .ViewTicketSidebarRightColumn {
    width: 365px;
    max-width: 40%;
    flex-shrink: 0;

    section {
      @extend .bordered-bottom;

      padding: 30px 24px;

      .DetailGroup {
        display: flex;
        align-items: center;

        label, .DetailValue {
          font-size: 13px;
          font-weight: 400;
        }

        label {
          color: var(--text-color-secondary);
          width: 85px;
          margin: 0 10px 0 0;
        }

        .DetailValue {
          flex: 1;
        }
      }
    }

    .CollapsibleCard {
      @extend .bordered-bottom;

      box-shadow: none;
      padding: 30px 24px !important;
      border-radius: 0;

      .CollapsibleCardContent,
      .CollapsibleCardHeader {
        padding: 0 !important;
      }

      h6 {
        @extend .text-small;
      }
    }
  }

  .SidebarCloseButton {
    right: 20px;
    top: 20px;
  }

  .TicketDropdown {
    right: 24px;
  }

  .MessagingIcon {
    width: 16px;
    height: 16px;

    svg {
      width: 16px;
      height: 16px;
    }
  }
}
