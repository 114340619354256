.AskAiForm {
  .AskAiForm-input-group {
    position: relative;

    input {
      padding-right: 50px;
      height: 39px;
      font-size: 13px;

      &::placeholder {
        color: rgba(0, 0, 0, 0.25);
      }

      &:focus {
        border-color: var(--link-color);
      }
    }

    button {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      background: transparent;
      border: none;
      padding: 5px;
    }

    svg {
      fill: var(--highlight-color);
    }
  }
}
