.PageNavAndRoutes {
  display: flex;
  position: relative;

  .WrappedContent {
    flex-grow: 1;
    margin-left: calc(-1 * var(--pages-nav-width));
    transition: margin-left 0.5s ease-in-out;
    width: 100vw;

    &.isDesktopNavOpen {
      margin-left: 0;
      width: calc(100vw - var(--pages-nav-width));
    }

    @media print {
      margin-left: 0;
      width: 100vw;
    }
  }

  .ToggleMenuButton {
    top: 15px;

    &.isOpen {
      left: calc(var(--pages-nav-width) + 10px);
    }
  }
}
