nav.LeftNav.PagesNav {
  position: relative;
  flex-grow: 0;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  overflow: auto;
  padding-bottom: 40px;

  width: var(--pages-nav-width);
  padding: 24px 15px;
  background-color: var(--base-layout-sidebar-background-color);
  left: calc(-1 * var(--pages-nav-width));

  .PagesNavTitle {
    padding-left: 18px;
    margin-bottom: 0;
  }

  @media print {
    display: none !important;
  }

  &.isDesktopNavOpen {
    left: 0;
  }
}
