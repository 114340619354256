.SmartPdfAttachment {
  &-wrapper {

    &__attachment_title {
      display: flex;
      align-items: center;
      gap: 8px;

      svg {
        width: 16px;
        height: 16px;

        path {
          fill: var(--text-color-secondary);
        }
      }
    }

    &__attachment_content {
      &--light {
        background-color: var(--white-pure);
      }

      .eye-icon {
        svg {
          width: 16px;
          height: 16px;

          path {
            fill: var(--text-color-secondary);
          }
        }
      }

      .edit-icon {
        svg {
          width: 12px;
          height: 12px;

          path {
            fill: var(--text-color-secondary);
          }
        }
      }
    }
  }

}
