.CreateTicketSidebar {
  .react-datepicker-popper {
    z-index: $zindex-popover;
  }

  .ClearyReactSelect__menu {
    z-index: $zindex-popover;
  }

  .TipTapEditor {
    min-height: 300px;
  }
}
