.AskAiPage {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;

  header {
    @extend .bordered-bottom;

    width: 100%;
    height: 62px;
    background-color: var(--white-pure);
    display: flex;
    align-items: center;
    justify-content: center;

    .TitleAndActions {
      width: min(833px, 60%);

      button {
        background: transparent;
        border: none;
        padding: 0;

        svg {
          width: 18px;
          height: 18px;

          path {
            fill: var(--text-color-primary);
          }

          &:hover {
            @extend .pointer;

            path {
              fill: var(--highlight-color);
            }
          }
        }
      }
    }
  }

  .Greeting {
    background: linear-gradient(to right, #5246D7, #B830F8);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    color: transparent;
  }

  .AskAiContent {
    width: min(833px, 60%);
    height: calc(100% - 62px); // 62px is the height of the header
    display: flex;
    flex-direction: column;
    position: relative;
    justify-content: space-between;

    &.isNewConversation {
      justify-content: center;
    }
  }
}
