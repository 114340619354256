.FaqEditorSidebar {
  .RootSidebarContent {
    display: flex;
    flex-direction: column;
    gap: 30px;
  }

  .SidebarCloseButton {
    top: -15px;
  }

  .TiptapEditorContentWrapper {
    @extend .p-3;
  }
}
